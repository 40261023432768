//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { computed, onMounted } from '@nuxtjs/composition-api';

const BASE_CLASS = 'app-reviews';
export default {
  name: 'Reviews',
  props: {
    showTrustpilot: {
      type: Boolean,
      default: true
    },
    cssFlags: {
      type: Array,
      required: false,
      defualt: () => []
    },
    title: {
      type: String,
      required: false,
      default: ''
    },
    widget: {
      type: String,
      default: 'testimonial'
    }
  },
  components: {
    AppHeadline: () => import(/* webpackChunkName: "chunk-ui-molecules" */ '@/components/UI/molecules/SectionHeadline')
  },
  setup(props) {
    const mappedCssFlags = computed(() => props.cssFlags?.map((f) => `${BASE_CLASS}--${f}`) || ['']);
    const containerClasses = computed(() => [BASE_CLASS, ...mappedCssFlags.value]);

    function loadTrustPilot(trustboxType) {
      const trustbox = document.getElementById(trustboxType);
      if (window.Trustpilot && trustbox) window.Trustpilot.loadFromElement(trustbox);
    }

    onMounted(() => {
      if (props.widget === 'testimonial') {
        loadTrustPilot('trustboxTestimonial');
      } else if (props.widget === 'horizontal') {
        loadTrustPilot('trustboxBadge');
      } else {
        loadTrustPilot('trustboxMicroStar');
      }
    });

    return {
      containerClasses,
      mappedCssFlags
    };
  },
  head() {
    return {
      script: [
        {
          src: this.$config.trustpilot.widget_script,
          type: 'text/javascript',
          async: true
        }
      ]
    };
  }
};
