//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { computed, ref, useContext, onMounted } from '@nuxtjs/composition-api';
import { useGlobalUI } from '@/composables/useGlobalUI';
import { formatGtm, useCategory } from '@odyssey/realtainment';
import omit from 'lodash/omit';
export default {
  components: {
    Spinner: () => import(/* webpackChunkName: "chunk-ui-atoms" */ '@/components/UI/atoms/Spinner'),
    MagnifyingGlassIcon: () =>
      import(/* webpackChunkName: "chunk-icons" */ '@/static/assets/artnight/icons/icon--magnifying-glass.svg?inline'),
    VideoCameraIcon: () =>
      import(/* webpackChunkName: "chunk-icons" */ '@/static/assets/artnight/icons/icon--video-camera.svg?inline'),
    CrosshairIcon: () =>
      import(/* webpackChunkName: "chunk-icons" */ '@/static/assets/artnight/icons/icon--crosshair.svg?inline')
  },
  props: {
    cities: {
      type: Array,
      required: false,
      default: () => []
    },
    city: {
      type: Object,
      default: () => null
    },
    loading: {
      type: Boolean,
      default: false
    },
    showOnline: {
      type: Boolean,
      default: true
    },
    showSearch: {
      type: Boolean,
      default: true
    },
    isModalPrompt: {
      type: Boolean,
      default: false
    },
    shouldHref: {
      type: Boolean,
      default: false
    },
    isPanelFilter: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const { $gtm, route } = useContext();
    const { toggleGlobalUI } = useGlobalUI();
    const searchInput = ref(null);
    const { categories } = useCategory();

    const onlineCategory = computed(() => categories.value.find((category) => category.slug === 'online-events'));
    const filteredCategories = computed(() => {
      const cats = props.cities.length ? props.cities : categories.value;

      if (searchInput.value === null || searchInput.value.length === '') {
        return cats.filter((category) => category.includeInMenu && category.productCount > 0);
      }

      return cats.filter(
        (category) =>
          category.includeInMenu &&
          category.productCount > 0 &&
          category.name.toLowerCase().includes(searchInput.value.toLowerCase())
      );
    });

    const isSelected = ref([]);

    function setSelectedCity(param) {
      isSelected.value = filteredCategories.value.map((city) => city.slug === param);
    }
    const onModalItemSelected = (categorySlug) => {
      if (
        (route.value.path === '/' && props.isModalPrompt) ||
        (route.value.path?.includes('/motiv/') && props.isModalPrompt)
      ) {
        localStorage.setItem('CITY_MODAL_SELECTED', categorySlug);
        $gtm.push(
          formatGtm('modal/CITY_SELECTION_MODAL_SUBMIT', {
            location: route.value.meta.pageType,
            selection: categorySlug
          })
        );
      } else {
        $gtm.push(
          formatGtm('listing/CITY_PANEL_INPUT_SUBMIT', {
            location: route.value.meta.pageType,
            selection: categorySlug
          })
        );
      }
    };
    const onInputFocus = () => {
      $gtm.push(
        formatGtm('listing/CITY_PANEL_INPUT_FOCUS', {
          location: route.value.meta.pageType
        })
      );
    };

    onMounted(() => {
      const currentRoute = route.value.params.slug;
      const selectedCityonMounted = filteredCategories.value.filter((city) => city.slug === currentRoute);
      if (selectedCityonMounted.length) {
        emit('updated-filter', selectedCityonMounted[0]?.slug);
        setSelectedCity(selectedCityonMounted[0]?.slug);
      }
    });

    return {
      searchInput,
      onlineCategory,
      filteredCategories,
      onModalItemSelected,
      onInputFocus,
      toggleGlobalUI,
      route,
      omit,
      setSelectedCity,
      isSelected
    };
  }
};
