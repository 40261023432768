//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ref } from '@nuxtjs/composition-api';

export default {
  name: 'Accordion',
  components: {
    TransitionExpand: () =>
      import(/* webpackChunkName: "chunk-ui-molecules" */ '@/components/UI/molecules/TransitionExpand'),
    CaretDownIcon: () =>
      import(/* webpackChunkName: "chunk-icons" */ '@/static/assets/artnight/icons/icon--caret-down.svg?inline')
  },
  props: {
    /**
     * If the title is just a simple string, it can be passed in as a prop
     */
    title: {
      type: String,
      default: null
    },
    shouldBeOpenInitially: {
      type: Boolean,
      default: false
    },
    arrows: {
      type: String,
      default: 'right'
    }
  },
  setup(props) {
    const isOpen = ref(props.shouldBeOpenInitially ?? false);

    const toggle = () => {
      isOpen.value = !isOpen.value;
    };

    return {
      isOpen,
      toggle
    };
  }
};
