//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'STeamEvent',
  components: {
    AppHeadline: () => import(/* webpackChunkName: "chunk-ui-molecules" */ '@/components/UI/molecules/SectionHeadline'),
    NuxtTransformImage: () =>
      import(/* webpackChunkName: "chunk-ui-molecules" */ '@/components/UI/molecules/NuxtTransformImage')
  },
  props: {
    teamEventData: {
      type: Object
    }
  },
  setup() {
    const data = {
      title: 'Malkurse für Gruppen und Firmen',
      text: 'Das kreativste Teamevent für jeden Anlass!',
      buttonName: 'Mehr Infos und Terminanfrage',
      image: '/assets/artnight/logos/team.png'
    };
    return { data };
  }
};
