import { computed, reactive, ref } from '@nuxtjs/composition-api';

const getDefaultFormData = () => ({
  eventInfo: {
    occasion: 'teamevent',
    theme: { eventThemeUuid: '', price: 0 },
    themeSKU: null,
    numberOfParticipants: null,
    eventDate: null,
    eventTime: null,
    locationCity: null,
    locationAddress: null,
    locationPostalCode: null,
    locationContact: '',
    locationType: 'customer'
  }
});

const formData = reactive(getDefaultFormData());

export const useOrderingFlow = () => {
  const formDataComputed = computed(() => formData);

  // Update formData property with the new value
  function setFormData(data, parent) {
    Object.keys(data).forEach((key) => {
      parent ? (formData[parent][key] = data[key]) : (formData[key] = data[key]);
    });
  }

  function resetFormData() {
    const defaults = getDefaultFormData();
    Object.keys(formData).forEach((key) => (formData[key] = defaults[key]));
  }

  const bookingPrice = computed(() => {
    if (formData.eventInfo.theme.price > 0) {
      return formData.eventInfo.theme.price * getProductQuantity(formData.eventInfo.numberOfParticipants);
    }

    return 66000;
  });
  function getProductOptions() {
    return {
      eventDate: formData.eventInfo.eventDate,
      eventTime: formData.eventInfo.eventTime,
      locationCity: formData.eventInfo.locationCity,
      locationAddress: formData.eventInfo.locationAddress,
      locationPostalCode: formData.eventInfo.locationPostalCode,
      locationContact: formData.eventInfo.locationContact,
      numberOfParticipants: formData.eventInfo.numberOfParticipants
    };
  }

  function getProductQuantity(capacity) {
    const value = parseInt(capacity);
    return value < 15 ? 15 : value;
  }

  return {
    formData,
    formDataComputed,
    bookingPrice,
    setFormData,
    resetFormData,
    getProductOptions,
    getProductQuantity
  };
};
