//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { toRefs, reactive } from '@nuxtjs/composition-api';

export default {
  components: {
    OverflowContainer: () =>
      import(/* webpackChunkName: "chunk-ui-molecules" */ '@/components/UI/molecules/OverflowContainer'),
    SliderArrowIcon: () => import(/* webpackChunkName: "chunk-icons" */ '@/static/assets/artnight/icons/icon--slider-arrow.svg?inline')
  },
  setup() {
    const state = reactive({
      scrollContainer: null,
      isOverflowingAtStart: false,
      isOverflowingAtEnd: false
    });

    const handleOverflowStartChange = (newValue) => {
      state.isOverflowingAtStart = newValue;
    };

    const handleOverflowEndChange = (newValue) => {
      state.isOverflowingAtEnd = newValue;
    };

    const scrollLeft = () => {
      state.scrollContainer.$el.scrollBy({ left: -state.scrollContainer.$el.offsetWidth * 0.8, behavior: 'smooth' });
    };

    const scrollRight = () => {
      state.scrollContainer.$el.scrollBy({ left: state.scrollContainer.$el.offsetWidth * 0.8, behavior: 'smooth' });
    };

    return {
      ...toRefs(state),
      handleOverflowStartChange,
      handleOverflowEndChange,
      scrollLeft,
      scrollRight
    };
  }
};
