import { onMounted, onBeforeUnmount, isRef, watch, unref } from '@nuxtjs/composition-api';

function createScrollEventHandler(handler) {
  let ticking = false;

  const wrappedHandler = (event) => {
    if (!ticking) {
      window.requestAnimationFrame(() => {
        handler(event);
        ticking = false;
      });

      ticking = true;
    }
  };

  return wrappedHandler;
}

export function useEventListener(target, event, handler) {
  switch (event) {
    case 'scroll':
      if (target === window) {
        handler = createScrollEventHandler(handler);
      }

      break;
  }

  if (isRef(target)) {
    watch(target, (value, oldValue) => {
      oldValue?.removeEventListener(event, handler);
      value?.addEventListener(event, handler, {
        passive: true
      });
    });
  } else {
    onMounted(() => {
      target.addEventListener(event, handler);
    });
  }

  onBeforeUnmount(() => {
    unref(target)?.removeEventListener(event, handler);
  });
}
