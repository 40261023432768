//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { reactive, toRefs, computed, onMounted } from '@nuxtjs/composition-api';
import { isWeekend, format } from 'date-fns';

export default {
  name: 'MultiDatePicker',
  props: {
    text: {
      type: String
    },
    available: {
      type: Array,
      required: true,
      default: () => []
    },
    dayNames: {
      type: Array,
      required: true,
      default: () => []
    },
    monthNames: {
      type: Array,
      required: true,
      default: () => []
    },
    active: {
      type: Array,
      required: false,
      default: () => []
    },
    applied: {
      type: Array,
      required: false,
      default: () => []
    },
    displayedMonth: {
      type: Number,
      required: false,
      default: () => new Date().getMonth()
    },
    displayedYear: {
      type: Number,
      required: false,
      default: () => new Date().getFullYear()
    }
  },
  data() {
    return {
      panelShow: false,
      seleDate: new Date(),
      seleYear: this.displayedYear,
      seleMonth: this.displayedMonth
    };
  },
  setup(props, { emit }) {
    const state = reactive({
      panelShow: false,
      seleDate: new Date(),
      seleYear: props.displayedYear,
      seleMonth: props.displayedMonth
    });

    function toggleSelect(item) {
      if (item.hasEvents) {
        const highlightedDate = props.available.filter((variant) => {
          return variant.id === item.date;
        });

        if (highlightedDate.length) {
          emit('selected', highlightedDate[0]);
        }
      }
    }

    function changeMonth(num) {
      if (state.seleYear === new Date().getFullYear() && state.seleMonth + num < new Date().getMonth()) {
        return;
      }

      if (state.seleMonth + num > 11) {
        state.seleMonth = 0;
        state.seleYear++;
      } else if (state.seleMonth + num < 0) {
        state.seleMonth = 11;
        state.seleYear--;
      } else {
        state.seleMonth += num;
      }
    }

    const dates = computed(() => {
      const firDay = 0 - new Date(state.seleYear, state.seleMonth, 1).getDay() + 2;
      const res = {};
      for (let i = firDay, index = 0; index < 42; i++, index++) {
        const week = Math.floor(index / 7);
        if (typeof res[week] === 'undefined') {
          res[week] = [];
        }

        const day = new Date(state.seleYear, state.seleMonth, i);

        const highlightedDate = props.available.filter((item) => {
          return item.id === format(day, 'yyyy-MM-dd');
        });

        const selectedDate = props.active.filter((item) => {
          return item.id === format(day, 'yyyy-MM-dd');
        });

        const appliedDate = Array.isArray(props.applied)
          ? props.applied.filter((item) => {
              return item.id === format(day, 'yyyy-MM-dd');
            })
          : [];

        const calObj = {
          label: day.getDate(),
          date: highlightedDate.length ? highlightedDate[0].id : null,
          hasEvents: highlightedDate.length > 0,
          isSelected: appliedDate.length > 0 || selectedDate.length > 0,
          isInCurrentMonth: day.getMonth() === state.seleMonth,
          isWeekendDay: isWeekend(day)
        };
        res[week].push(calObj);
      }
      return res;
    });

    const currentMonth = computed(() => props.monthNames[state.seleMonth]);
    const currentYear = computed(() => state.seleYear);
    const isPreviousMonthDisabled = computed(
      () => state.seleMonth === new Date().getMonth() && new Date().getFullYear() === state.seleYear
    );

    onMounted(() => {
      if (Array.isArray(props.active) && props.active.length) {
        emit('mounted', props.active[0]);
      }
    });

    return {
      ...toRefs(state),
      toggleSelect,
      changeMonth,
      currentMonth,
      isPreviousMonthDisabled,
      dates,
      currentYear
    };
  }
};
