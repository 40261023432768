//
//
//
//
//

export default {
  name: 'CategorySelect',
  props: {
    cssClass: {
      type: Object,
      required: false,
      default: () => {}
    },
    text: {
      type: String,
      required: true
    },
    headingElem: {
      type: String,
      required: false,
      default: 'h2'
    },
    linkText: {
      type: String,
      required: true
    }
  }
};
