var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"accordion"},[_c('header',{staticClass:"accordion__header",on:{"click":_vm.toggle}},[_vm._t("header",function(){return [(_vm.arrows === 'left')?_c('CaretDownIcon',{class:{
          'accordion__state-indicator': true,
          'accordion__state-indicator--left': _vm.arrows === 'left',
          'accordion__state-indicator--rotate': _vm.isOpen
        }}):_vm._e(),_vm._v(" "),_c('span',{staticClass:"accordion__title",class:{ 'accordion__title--bold': _vm.isOpen }},[_vm._t("title",function(){return [_vm._v("\n          "+_vm._s(_vm.title)+"\n        ")]})],2),_vm._v(" "),(_vm.arrows === 'right')?_c('CaretDownIcon',{class:{
          'accordion__state-indicator': true,
          'accordion__state-indicator--right': _vm.arrows === 'right',
          'accordion__state-indicator--rotate': _vm.isOpen
        }}):_vm._e()]},{"isOpen":_vm.isOpen})],2),_vm._v(" "),_c('transition-expand',[(_vm.isOpen)?_c('div',{staticClass:"accordion__contentWrapper"},[_c('div',{staticClass:"accordion__content"},[_vm._t("default")],2)]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }