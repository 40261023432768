//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { computed } from '@nuxtjs/composition-api';
const BASE_CLASS = 'n-tile';
const SUPPORTED_CSS_FLAGS = [
  'justify-center',
  'fancy-image',
  'image-small',
  'image-contain',
  'center-content',
  'bold-content',
  'image-right',
  'image-top',
  'grey-text',
  'title-reduced-bottom-space'
];

export default {
  name: 'STile',
  components: {
    NuxtTransformImage: () =>
      import(/* webpackChunkName: "chunk-ui-molecules" */ '@/components/UI/molecules/NuxtTransformImage')
  },
  inheritAttrs: false,
  props: {
    title: {
      type: String,
      required: false,
      default: null
    },
    imageSrc: {
      type: String,
      required: false,
      default: null
    },
    imageMaxHeight: {
      type: Number,
      default: 80
    },
    cssFlags: {
      type: Array,
      required: false,
      default: () => [],
      validator: (flags) => flags.filter((f) => SUPPORTED_CSS_FLAGS.includes(f)).length === flags.length
    },
    titleElement: {
      type: String,
      default: 'p',
      validator: (v) => ['h2', 'h3', 'h4', 'p', 'span'].includes(v)
    }
  },
  setup(props) {
    const containerClasses = computed(() => [BASE_CLASS, ...props.cssFlags.map((f) => `${BASE_CLASS}--${f}`)]);

    return {
      BASE_CLASS,
      containerClasses
    };
  }
};
