//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ref, toRefs, watch, watchEffect, computed } from '@nuxtjs/composition-api';
import usePopper from '@/composables/usePopper';
import { uniqueId, debounce } from 'lodash';

export default {
  name: 'Popper',
  props: {
    className: {
      type: String
    },
    isVisible: {
      type: Boolean,
      default: false
    },
    transitionName: {
      type: String,
      default: 'fade'
    },

    /**
     * Preferred placement (the "auto" placements will choose the side with most space.)
     */
    placement: {
      type: String,
      default: 'bottom',
      validator: (value) => {
        return [
          'auto',
          'auto-start',
          'auto-end',
          'top',
          'top-start',
          'top-end',
          'bottom',
          'bottom-start',
          'bottom-end',
          'right',
          'right-start',
          'right-end',
          'left',
          'left-start',
          'left-end'
        ].includes(value);
      }
    },

    /**
     * Open the Popper after a delay (ms).
     */
    openDelay: {
      type: [Number, String],
      default: 0
    },

    /**
     * Close the Popper after a delay (ms).
     */
    closeDelay: {
      type: [Number, String],
      default: 0
    },

    /**
     * Offset in pixels along the trigger element
     */
    offsetSkid: {
      type: String,
      default: '0'
    },

    /**
     * Offset in pixels away from the trigger element
     */
    offsetDistance: {
      type: String,
      default: '12'
    },

    /**
     * Trigger the popper on hover
     */
    triggerOnHover: {
      type: Boolean,
      default: false
    },

    /**
     * Display an arrow on the popper
     */
    shouldShowArrow: {
      type: Boolean,
      default: false
    },

    /**
     * Stop arrow from reaching the edge of the popper
     */
    arrowPadding: {
      type: String,
      default: '0'
    },

    /**
     * Enable this to allow the Popper to dynamically flip sides when it runs out of space.
     */
    allowFlip: {
      type: Boolean,
      default: false
    },

    /**
     * If the content is just a simple string, it can be passed in as a prop
     */
    content: {
      type: String,
      default: null
    }
  },
  setup(props, { emit }) {
    const popperNodeId = ref('popper_' + uniqueId());

    const triggerNode = ref(null);
    const modifiedIsOpen = ref(false);

    const { offsetDistance, offsetSkid, isVisible, allowFlip, arrowPadding, placement, openDelay, closeDelay, triggerOnHover } =
      toRefs(props);
    const { isOpen, open, close } = usePopper({
      arrowPadding,
      emit,
      allowFlip,
      offsetDistance,
      offsetSkid,
      placement,
      popperNodeId,
      triggerNode
    });

    const manualMode = computed(() => isVisible.value !== null);
    const shouldShowPopper = computed(() => isOpen.value);

    const openPopperDebounce = debounce(open, openDelay.value);
    const closePopperDebounce = debounce(close, closeDelay.value);

    const openPopper = async () => {
      closePopperDebounce.cancel();
      openPopperDebounce();
    };

    const closePopper = async () => {
      openPopperDebounce.cancel();
      closePopperDebounce();
    };

    const togglePopper = () => {
      isOpen.value ? closePopper() : openPopper();
    };

    const popperListeners = computed(() => {
      if (triggerOnHover.value) {
        return {
          mouseover: openPopper,
          // mouseleave: closePopper,
          'keyup.ecs': closePopper
        };
      }

      return {
        click: togglePopper,
        focus: openPopper,
        'keyup.ecs': closePopper
      };
    });

    /**
     * In order to eliminate flickering or visibly empty Poppers due to
     * the transition when using the isOpen slot property, we need to return a
     * separate debounced value based on isOpen.
     */
    watch(isOpen, (isOpen) => {
      if (isOpen) {
        modifiedIsOpen.value = true;
        emit('open');
      } else {
        debounce(() => {
          modifiedIsOpen.value = false;
          emit('close');
        }, 200)();
      }
    });

    watchEffect(() => {
      if (manualMode.value) {
        isVisible.value ? openPopperDebounce() : closePopperDebounce();
      }
    });

    return {
      triggerNode,
      popperNodeId,
      openPopper,
      closePopper,
      togglePopper,
      modifiedIsOpen,
      close,
      shouldShowPopper,
      popperListeners
    };
  }
};
