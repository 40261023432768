//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ref, reactive, toRefs, useContext } from '@nuxtjs/composition-api';

export default {
  name: 'LFounder',
  components: {
    ModalWrapper: () =>
      import(/* webpackChunkName: "chunk-ui-molecules-modals" */ '@/components/UI/molecules/modals/ModalWrapper'),
    ArrowRight: () =>
      import(/* webpackChunkName: "chunk-icons" */ '@/static/assets/artnight/icons/arrow-right.svg?inline'),
    NuxtTransformImage: () =>
      import(/* webpackChunkName: "chunk-ui-molecules" */ '@/components/UI/molecules/NuxtTransformImage')
  },
  props: {
    title: {
      type: String
    },
    text: {
      type: String
    },
    image: {
      type: String,
      default: ''
    },
    ctaText: {
      type: String
    },
    ctaLink: {
      type: Object,
      default: () => {}
    }
  },
  setup(props) {
    const isOpenVideo = ref(false);
    const context = useContext();
    const { i18n } = context;

    const propsState = reactive({
      titleProp: props.title ?? i18n.t('founder.title'),
      textProp: props.text ?? i18n.t('founder.text'),
      ctaProp: props.ctaText ?? i18n.t('founder.ctaText'),
      modal: [
        {
          title: i18n.t('founder.video_title'),
          content: {
            component: 'iframe',
            props: {
              src: 'https://www.youtube.com/embed/4fjqfTKW-oQ',
              style: 'border: 0;',
              height: '400',
              width: '100%',
              allow: 'autoplay; fullscreen; picture-in-picture',
              allowfullscreen: true
            }
          }
        }
      ]
    });

    return {
      isOpenVideo,
      ...toRefs(propsState)
    };
  }
};
