//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { onMounted, ref } from '@nuxtjs/composition-api';
export default {
  name: 'Input',
  components: {
    ValidationMessage: () => import(/* webpackChunkName: "chunk-ui-atoms" */ '@/components/UI/atoms/ValidationMessage')
  },
  props: {
    type: {
      type: String,
      required: true
    },
    value: {
      type: [String, Number],
      default: ''
    },
    name: {
      type: String,
      required: false,
      default: ''
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    autocomplete: {
      type: String,
      required: false,
      default: ''
    },
    focus: {
      type: Boolean,
      required: false,
      default: false
    },
    autofocus: {
      type: Boolean,
      required: false,
      default: false
    },
    validation: {
      type: Object,
      required: false,
      default: () => {}
    },
    validations: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  setup(props) {
    const inputRef = ref(null);

    onMounted(() => {
      if (props.focus) {
        inputRef.value.focus();
      }
    });

    return {
      inputRef
    };
  }
};
