//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { computed } from '@nuxtjs/composition-api';
export default {
  name: 'Stepper',
  components: {
    SButton: () => import(/* webpackChunkName: "chunk-ui-molecules-buttons" */ '@/components/UI/molecules/buttons/SButton')
  },
  props: {
    steps: {
      type: Array,
      default: () => [],
      required: false
    },
    currentStep: {
      type: Number,
      default: 1,
      required: false
    },
    title: {
      type: String,
      required: true
    },
    isNextDisabled: {
      type: Boolean,
      required: false,
      default: false
    },
    isBackDisabled: {
      type: Boolean,
      required: false,
      default: false
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    buttonBackLabel: {
      type: String,
      required: false,
      default: 'Zurück'
    },
    buttonNextLabel: {
      type: String,
      required: false,
      default: 'Weiter'
    },
    showStepper: {
      type: Boolean,
      required: false,
      default: true
    },
    showBackBtnOnLastStep: {
      type: Boolean,
      required: false,
      default: false
    },
    showBackBtnOnFirstStep: {
      type: Boolean,
      required: false,
      default: false
    },
    centerButtons: {
      type: Boolean,
      required: false,
      default: false
    },
    fullWidth: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup(props, { slots, emit }) {
    const stepsKeys = computed(() => props.steps.map((_, index) => index + 1));
    const stepsCount = computed(() => stepsKeys.value.length);
    const slotsKeys = computed(() => [].concat(...props.steps));
    const isLastStep = computed(() => props.currentStep === stepsCount.value);
    const isNextButtonVisible = computed(() => {
      return props.steps.length > 1 &&
        props.steps.indexOf(props.currentStep) !== props.steps.length - 1;
    });
    const isBackButtonVisible = computed(() => {
      const currentStepIndex = props.steps.indexOf(props.currentStep);
      return props.isLastStep
        ? props.showBackBtnOnLastStep
        : currentStepIndex > 0
          ? true
          : props.showBackBtnOnFirstStep;
    });

    const hasContentSlot = computed(() => slots.content !== undefined);
    const containerClasses = computed(() => ({
      'stepper--withoutContent': !hasContentSlot.value && !props.fullWidth
    }));

    const onNext = () => emit('next');
    const onBack = () => emit('back');
    const getStepClasses = (step) => ({
      'stepper__steps__step--current': props.currentStep === step,
      'stepper__steps__step--active': props.currentStep > step
    });

    return {
      hasContentSlot,
      containerClasses,
      stepsKeys,
      stepsCount,
      slotsKeys,
      isLastStep,
      isNextButtonVisible,
      isBackButtonVisible,
      onNext,
      onBack,
      getStepClasses
    };
  }
};
