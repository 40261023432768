//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  components: {
    Accordion: () => import(/* webpackChunkName: "chunk-ui-molecules" */ '@/components/UI/molecules/Accordion')
  },
  props: {
    title: {
      type: String
    },
    description: {
      type: String
    },
    items: {
      type: Array
    },
    arrows: {
      type: String,
      default: 'left'
    },
    horizontalAlign: {
      type: Boolean,
      default: false
    }
  }
};
