import { render, staticRenderFns } from "./SFounder.vue?vue&type=template&id=3cc29e80&scoped=true"
import script from "./SFounder.vue?vue&type=script&lang=js"
export * from "./SFounder.vue?vue&type=script&lang=js"
import style0 from "./SFounder.vue?vue&type=style&index=0&id=3cc29e80&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3cc29e80",
  null
  
)

export default component.exports