//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { computed, useContext } from '@nuxtjs/composition-api';

const BASE_CLASS = 's-general-banner';
import { format, parseISO } from 'date-fns';

export default {
  name: 'SGeneralBanner',
  components: {
    NButton: () =>
      import(/* webpackChunkName: "chunk-ui-molecules-buttons" */ '@/components/UI/molecules/buttons/SButton'),
    SBannerContent: () => import(/* webpackChunkName: "chunk-ui-atoms" */ '@/components/UI/molecules/SBannerContent')
  },
  props: {
    bannerSize: {
      type: String,
      required: true
    },
    content: {
      type: Array,
      required: false
    },
    background: {
      type: Object,
      default: () => {},
      required: true
    },
    link: {
      type: Object,
      default: null,
      required: true
    },
    trackingId: {
      type: String,
      required: false
    },
    button: {
      type: Array,
      default: false,
      required: false
    },
    buttonPosition: {
      type: String,
      required: false
    },
    opacity: {
      type: Object,
      required: false
    },
    textAlign: {
      type: String,
      required: false
    },
    textJustify: {
      type: String,
      required: false
    },
    endDate: {
      type: String,
      required: false
    }
  },
  setup(props, { root }) {
    const { $img } = useContext();
    const parsedCurrentDate = format(new Date(), 'yyyy-MM-dd HH:mm');
    const isoCurrentDate = parseISO(parsedCurrentDate);
    const isoEndDate = parseISO(props.endDate);

    const isActive = computed(() => (props.endDate.length ? isoCurrentDate < isoEndDate : true));

    const bannerSizeClass = computed(() => {
      if (props.bannerSize) {
        return [`${BASE_CLASS}--${props.bannerSize}`];
      }
    });

    const backgroundClasses = computed(() => {
      const classes = [];

      if (String(props.background?.mobileImage ?? '').length) {
        classes.push(`${BASE_CLASS}__background--background-image-mobile`);
      }
      if (String(props.background?.desktopImage ?? '').length) {
        classes.push(`${BASE_CLASS}__background--background-image-desktop`);
      }
      return classes;
    });

    const backgroundCssVars = computed(() => {
      const cssVars = {
        '--background-color': (props.background?.color ?? 'transparent') || 'transparent',
        '--background-opacity': props?.opacity?.value
      };

      if (String(props.background?.mobileImage ?? '').length) {
        cssVars['--background-image-mobile'] = `url("${$img(
          props.background.mobileImage,
          {
            format: 'webp'
          },
          {
            provider: 'storyblok'
          }
        )}")`;
        cssVars['--background-image-mobile-size'] = props.background.mobileImageSize || 'cover';
      }

      if (String(props.background?.desktopImage ?? '').length) {
        cssVars['--background-image-desktop'] = `url("${$img(
          props.background.desktopImage,
          {
            format: 'webp'
          },
          {
            provider: 'storyblok'
          }
        )}")`;
        cssVars['--background-image-desktop-size'] = props.background.desktopImageSize || 'cover';
      }
      return cssVars;
    });
    const isMobile = computed(() => root?.$screen.sm === false);

    return {
      backgroundCssVars,
      backgroundClasses,
      bannerSizeClass,
      isMobile,
      isActive
    };
  }
};
