//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ref, computed, useRoute, useContext } from '@nuxtjs/composition-api';
import { useEventListener } from '@/composables';
import { useCart, useCategory } from '@odyssey/realtainment';
import { useGlobalUI, GlobalUIElements } from '@/composables/useGlobalUI';
export default {
  setup() {
    const route = useRoute();
    const { i18n } = useContext();
    const { cart } = useCart();
    const { categories } = useCategory();
    const { setGlobalUI } = useGlobalUI();

    const widgetRef = ref(null);
    const floatingCallToActioSButtonRef = ref(null);

    const lastExpandedScrollPos = ref(0);
    const isInitiallyExpanded = false;
    const isExpanded = ref(isInitiallyExpanded);
    const shouldShowCallToActioSButton = ref(false);

    const isGroupBooking = computed(() => route.value.path === '/gruppenevents/');

    const cityOptions = computed(() =>
      categories.value
        .filter((category) => category.includeInMenu && category.productCount > 0)
        .map((category) => ({ name: category.name, id: category.slug }))
    );

    const isCategoryRoute = computed(
      () => categories.value.find((c) => c.slug === route.value.params?.slug) !== undefined
    );

    const buttonText = computed(() =>
      route.value.meta?.pageType === 'category' || isCategoryRoute.value
        ? i18n.t('category.filters.label')
        : i18n.t('event.find_your_artnight')
    );

    const handleExpand = () => {
      lastExpandedScrollPos.value = window.scrollY;
      isExpanded.value = true;

      setGlobalUI(GlobalUIElements.FiltersModal, true);
    };

    useEventListener(window, 'scroll', () => {
      const scrollPosition = window.scrollY;

      shouldShowCallToActioSButton.value = scrollPosition > 400;

      // collapse if scrolled more than x px after expanding
      if (isExpanded.value && Math.abs(lastExpandedScrollPos.value - scrollPosition) > 500) {
        isExpanded.value = false;
      }
    });

    const isHidden = computed(
      () =>
        route.value?.name?.includes('product-event') && cart.value && cart.value.items && cart.value.items.length === 0
    );

    return {
      route,
      cityOptions,
      buttonText,
      floatingCallToActioSButtonRef,
      handleExpand,
      isExpanded,
      isHidden,
      shouldShowCallToActioSButton,
      widgetRef,
      isGroupBooking
    };
  }
};
