//
//
//
//
//
//
//
//
//
//
//

export default {
  components: {
    NuxtTransformImage: () => import(/* webpackChunkName: "chunk-ui-molecules" */ '@/components/UI/molecules/NuxtTransformImage')
  },
  props: {
    socialLinks: {
      type: Array
    }
  }
};
