//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { computed, reactive, toRefs } from '@nuxtjs/composition-api';
const BASE_CLASS = 'n-picture';
const SUPPORTED_CSS_FLAGS = ['cover', 'contain', 'content-media', 'rounded', 'is-centered', 'space-y'];

export default {
  name: 'SPicture',
  components: {
    Spinner: () => import(/* webpackChunkName: "chunk-ui-atoms" */ '@/components/UI/atoms/Spinner'),
    NuxtTransformImage: () =>
      import(/* webpackChunkName: "chunk-ui-molecules" */ '@/components/UI/molecules/NuxtTransformImage')
  },
  inheritAttrs: false,
  props: {
    src: {
      type: String,
      required: false
    },
    cssFlags: {
      type: Array,
      required: false,
      default: () => [],
      validator: (flags) => flags.filter((f) => SUPPORTED_CSS_FLAGS.includes(f)).length === flags.length
    },
    slides: {
      type: Array,
      required: false,
      default: () => []
    },
    title: {
      type: String,
      required: false,
      default: null
    },
    link: {
      type: [String, Object],
      required: false,
      default: ''
    },
    slideDurationMs: {
      type: Number,
      default: 8000,
      required: false
    },
    showDescription: {
      type: Boolean,
      default: true
    },
    rewriteWebp: {
      type: Boolean,
      default: false
    },
    showLoading: {
      type: Boolean,
      default: false
    },
    maxHeight: {
      type: Number,
      required: false
    },
    alt: {
      type: String,
      required: false
    },
    provider: {
      type: String,
      default: 'storyblok'
    },
    id: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const state = reactive({
      isLoading: true,
      activeSlideIndex: 0,
      updateInterval: null
    });

    const cssVars = computed(() => ({
      '--max-height': props.maxHeight ? `${props.maxHeight}px` : 'auto'
    }));

    const items = computed(() => {
      const singleSlide = props.src ? { src: props.src, title: props.title, alt: props.alt } : null;

      const slides = props.slides.map(({ src, title }) => ({ title, src, alt: props.alt }));

      return [singleSlide, ...slides].filter((slides) => slides !== null);
    });

    const isLinkProp = computed(() => Object.keys(props.link).length && props.link.linktype === 'url');

    const isLinkUrl = computed(
      () => (isLinkProp.value && props.link.cached_url.length) || (isLinkProp.value && props.link.url.length)
    );

    const wrapperComponent = computed(() => (isLinkUrl.value ? 'a' : 'div'));

    const wrapperAttributes = computed(() => (isLinkUrl.value ? { href: props.link.cached_url } : {}));

    const containerClasses = computed(() => ({
      [BASE_CLASS]: true,
      ...props.cssFlags.reduce((acc, f) => ({ ...acc, [`${BASE_CLASS}--${f}`]: true }), {}),
      [`${BASE_CLASS}--is-loading`]: props.showLoading && state.isLoading
    }));

    function setUpdateInterval() {
      const totalSlides = items.value.length;
      if (totalSlides < 2 || !process.client) return;

      state.updateInterval = setInterval(() => {
        const nextSlideIndex = state.activeSlideIndex === totalSlides - 1 ? 0 : state.activeSlideIndex + 1;

        state.activeSlideIndex = nextSlideIndex;
      }, props.slideDurationMs);
    }

    setUpdateInterval();

    function handleLoad() {
      // Set isLoading after random timeout to imitate loading
      const randomIndex = Math.random() * (8 - 0) + 0;
      setTimeout(() => {
        state.isLoading = false;
      }, randomIndex * 0.2 * 200);
    }

    return {
      ...toRefs(state),
      cssVars,
      containerClasses,
      items,
      handleLoad,
      wrapperComponent,
      wrapperAttributes
    };
  }
};
