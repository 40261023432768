//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { computed } from '@nuxtjs/composition-api';
const BASE_CLASS = 'n-logo-row';
export default {
  components: {
    NuxtTransformImage: () => import(/* webpackChunkName: "chunk-ui-molecules" */ '@/components/UI/molecules/NuxtTransformImage')
  },
  props: {
    label: {
      type: String,
      required: false,
      default: ''
    },
    logos: {
      type: Array,
      required: false,
      default: () => []
    },
    cssFlags: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  setup(props) {
    const containerClasses = computed(() => [BASE_CLASS, ...props.cssFlags.map((f) => `${BASE_CLASS}--${f}`)]);
    const cssVars = computed(() => ({
      '--grid-columns': props.logos.length
    }));

    function getLogoClasses(item) {
      return [item.cssClass, (item.hideOnMobile ? 'd-md-down-none' : '') + ' d-md-flex'];
    }
    return {
      cssVars,
      getLogoClasses,
      containerClasses
    };
  }
};
