//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { computed } from '@nuxtjs/composition-api';
export default {
  name: 'CouponCode',
  components: {
    Input: () => import(/* webpackChunkName: "chunk-ui-molecules" */ '@/components/UI/molecules/Input'),
    SButton: () => import(/* webpackChunkName: "chunk-ui-molecules-buttons" */ '@/components/UI/molecules/buttons/SButton')
  },
  props: {
    value: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    },
    buttonText: {
      type: String,
      required: true
    },
    inputPlaceholder: {
      type: String,
      required: false,
      default: ''
    },
    inputActive: {
      type: Boolean,
      required: true,
      default: false
    },
    couponAdding: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const isHeaderVisible = computed(() => {
      if (props.inputActive && typeof window !== 'undefined') {
        if (window.innerWidth >= 1024) {
          return false;
        }
      }
      return true;
    });

    return {
      isHeaderVisible
    };
  }
};
