//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { watch, reactive, toRefs, computed, onMounted } from '@nuxtjs/composition-api';
export default {
  name: 'Dropdown',
  components: {
    ValidationMessage: () => import(/* webpackChunkName: "chunk-ui-atoms" */ '@/components/UI/atoms/ValidationMessage'),
    Input: () => import(/* webpackChunkName: "chunk-ui-molecules" */ '@/components/UI/molecules/Input')
  },
  props: {
    name: {
      type: String,
      required: false,
      default: 'dropdown',
      note: 'Input name'
    },
    value: {
      required: true
    },
    options: {
      type: Array,
      required: true,
      default: () => [],
      note: 'Options of dropdown. An array of options with id and name'
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Please select an option',
      note: 'Placeholder of dropdown'
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
      note: 'Disable the dropdown'
    },
    validations: {
      type: Array,
      default: () => []
    },
    withArrow: {
      type: Boolean,
      required: false,
      default: false
    },
    invertedArrow: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup(props, { emit }) {
    const state = reactive({
      selected: null,
      optionsShown: false,
      searchFilter: ''
    });

    const filteredOptions = computed(() => {
      const filtered = [];
      for (const option of props.options) {
        if (option.name && option.name.toLowerCase().includes(state.searchFilter.toLowerCase())) {
          filtered.push(option);
        } else if (option.id && String(option.id).toLowerCase().includes(state.searchFilter.toLowerCase())) {
          filtered.push(option);
        }
      }
      return filtered;
    });

    onMounted(() => {
      const selected = state.selected || props.value;
      if (selected) {
        const selectedFilter = props.options.filter((option) => option.id === selected);
        if (selectedFilter.length) {
          state.selected = selectedFilter[0];
          state.searchFilter = state.selected.name;
        }
      }
    });

    function selectOption(option) {
      state.selected = option;
      state.optionsShown = false;
      state.searchFilter = state.selected.name;
      emit('input', state.selected.id);
      emit('selected', state.selected);
    }

    function showOptions() {
      if (!state.disabled) {
        state.searchFilter = '';
        state.optionsShown = true;
        emit('open', true);
      }
    }
    function exit() {
      if ([undefined, null, ''].includes(state.selected?.id)) {
        state.selected = {};
        state.searchFilter = '';
      } else {
        state.searchFilter = state.selected.name;
      }
      state.optionsShown = false;
    }
    // Selecting when pressing Enter
    function keyMonitor(event) {
      if (event.key === 'Enter' && state.filteredOptions?.[0]) {
        state.selectOption(state.filteredOptions[0]);
      }
    }

    watch(
      () => state.searchFilter,
      () => {
        if (filteredOptions.value.length === 0) {
          state.selected = {};
        } else {
          state.selected = filteredOptions.value[0];
        }
        emit('filter', state.searchFilter);
      }
    );

    watch(
      () => props.value,
      (val) => {
        const foundOption = props.options.find((option) => option.id === val);
        if (foundOption) selectOption(foundOption);
      }
    );

    return {
      ...toRefs(state),
      filteredOptions,
      selectOption,
      showOptions,
      exit,
      keyMonitor
    };
  }
};
