//
//
//
//
//

import { toRefs, reactive, onMounted, onUnmounted } from '@nuxtjs/composition-api';
import debounce from 'lodash/debounce';
import ResizeObserverPolyfill from 'resize-observer-polyfill';

export default {
  props: {
    direction: {
      type: String,
      validator: (val) => ['horizontal', 'vertical'].includes(val),
      default: 'horizontal'
    }
  },
  setup(props, { emit }) {
    const state = reactive({
      container: null,
      isOverflowingAtStart: false,
      isOverflowingAtEnd: false
    });

    const checkOverflow = debounce((element) => {
      const scrollStartProperty = props.direction === 'horizontal' ? 'scrollLeft' : 'scrollTop';
      const offsetDimensionProperty = props.direction === 'horizontal' ? 'offsetWidth' : 'offsetHeight';
      const scrollDimensionProperty = props.direction === 'horizontal' ? 'scrollWidth' : 'scrollHeight';

      const hasOverflow = element[offsetDimensionProperty] < element[scrollDimensionProperty];

      let hasOverflowAtStart = false;
      let hasOverflowAtEnd = false;

      if (hasOverflow) {
        hasOverflowAtStart = element[scrollStartProperty] > 10;
        hasOverflowAtEnd =
          element[scrollStartProperty] < element[scrollDimensionProperty] - element[offsetDimensionProperty] - 10;
      }

      if (state.isOverflowingAtStart !== hasOverflowAtStart) {
        emit('overflow-start-change', hasOverflowAtStart);
      }

      state.isOverflowingAtStart = hasOverflowAtStart;

      if (state.isOverflowingAtEnd !== hasOverflowAtEnd) {
        emit('overflow-end-change', hasOverflowAtEnd);
      }

      state.isOverflowingAtEnd = hasOverflowAtEnd;
    });

    let listElementResizeObserver = null;

    const debouncedListElementScrollEventHandler = debounce((event) => {
      checkOverflow(event.target);
    }, 200);

    const addListElementObservers = () => {
      listElementResizeObserver = new ResizeObserverPolyfill((entries) => {
        // based on https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
        window.requestAnimationFrame(() => {
          if (!Array.isArray(entries) || !entries.length) {
            return;
          }
          entries.forEach((entry) => {
            const element = entry.target;
            checkOverflow(element);
          });
        });
      });

      listElementResizeObserver.observe(state.container);

      if (state.container) {
        state.container.addEventListener('scroll', debouncedListElementScrollEventHandler, {
          passive: true
        });
      }
    };

    const removeListElementObservers = () => {
      if (listElementResizeObserver) {
        listElementResizeObserver.disconnect();
      }

      if (state.container) {
        state.container.removeEventListener('scroll', debouncedListElementScrollEventHandler);
      }
    };

    onMounted(() => {
      addListElementObservers();
    });

    onUnmounted(() => {
      removeListElementObservers();
    });

    return {
      ...toRefs(state)
    };
  }
};
