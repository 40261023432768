var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"city-selector"},[(_vm.showSearch)?_c('div',{staticClass:"city-selector__search"},[_c('label',{staticClass:"city-selector__search-label visually-hidden",attrs:{"for":"search"},domProps:{"textContent":_vm._s(_vm.city ? _vm.$t('category.search_title') : _vm.$t('category.select-city'))}}),_vm._v(" "),_c('div',{staticClass:"city-selector__search-input-group"},[_c('MagnifyingGlassIcon',{staticClass:"city-selector__search-icon"}),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchInput),expression:"searchInput"}],ref:"search",staticClass:"city-selector__search-input",attrs:{"id":"search","placeholder":_vm.$t('newsletter.placeholder_city'),"type":"text","autofocus":"true"},domProps:{"value":(_vm.searchInput)},on:{"focus":_vm.onInputFocus,"input":function($event){if($event.target.composing){ return; }_vm.searchInput=$event.target.value}}})],1),_vm._v(" "),(false)?_c('div',{staticClass:"city-selector__use-current-location"},[_c('a',{attrs:{"href":"#"}},[_c('CrosshairIcon'),_vm._v(" Aktuellen Standort verwenden")],1)]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"city-selector__show-online-events"},[_c('span',{staticClass:"pointer",on:{"click":function () {
            _vm.onModalItemSelected(_vm.onlineCategory.slug);
            _vm.$emit('change', _vm.onlineCategory);
          }}},[_c('VideoCameraIcon',{attrs:{"fill":"var(--color-primary, #EF7A52)"}}),_vm._v(" Nur Online Malkurse anzeigen\n      ")],1)])]):_vm._e(),_vm._v(" "),(_vm.loading)?_c('spinner'):_c('section',{staticClass:"city-selector__cities"},[(_vm.showSearch)?_c('header',{staticClass:"city-selector__cities-heading"},[_vm._v("Alle ArtNight Städte")]):_vm._e(),_vm._v(" "),(_vm.cities.length && !_vm.shouldHref)?_c('ol',{staticClass:"city-selector__cities-list"},_vm._l((_vm.filteredCategories),function(category,i){return _c('li',{key:category.slug,class:{
          'city-selector__cities-list--before-selected':
            i < _vm.filteredCategories.length - 1 && _vm.city && _vm.city.slug === _vm.filteredCategories[i + 1].slug,
          'city-selector__cities-list--selected': _vm.city && _vm.city.slug === category.slug,
          'city-selector__cities-list--divider': category.divider
        }},[(!category.divider)?_c('span',{attrs:{"title":_vm.$t('category.events_in', { city: category.name })},domProps:{"textContent":_vm._s(category.name)},on:{"click":function () {
              _vm.onModalItemSelected(category.slug);
              _vm.$emit('change', category);
            }}}):_vm._e()])}),0):(_vm.filteredCategories.length)?_c('ol',{staticClass:"city-selector__cities-list",attrs:{"data-testid":"mainCitySelectorOpened"}},_vm._l((_vm.filteredCategories),function(category,index){return _c('li',{key:category.slug,class:{
          'city-selector__cities-list--divider': category.divider
        }},[(_vm.isPanelFilter)?_c('span',{key:category.slug,class:{
            'city-selector__cities-list--highlighted': _vm.isSelected[index]
          },domProps:{"textContent":_vm._s(category.name)},on:{"click":function () {
              _vm.setSelectedCity(category.slug);
              _vm.$emit('updated-filter', category.slug);
            },"reset-filter":function($event){return _vm.setSelectedCity(null)}}}):(!category.divider)?_c('nuxt-link',{class:{
            'city-selector__cities-list--highlighted': _vm.isSelected[index]
          },attrs:{"to":_vm.localePath({
              name: 'variable-route',
              params: { slug: category.slug },
              query: _vm.omit(_vm.route.query, ['page'])
            }),"title":_vm.$t('category.events_in', { city: category.name })},domProps:{"textContent":_vm._s(category.name)},nativeOn:{"click":function($event){return (function () {
              _vm.onModalItemSelected(category.slug);
              _vm.$emit('change', category);
            }).apply(null, arguments)}}}):_vm._e()],1)}),0):_c('div',{staticClass:"city-selector__cities-no-results"},[_vm._v("\n      "+_vm._s(_vm.$t('category.search_no_cities', { query: _vm.searchInput }))+"\n    ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }