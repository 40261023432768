//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { computed } from '@nuxtjs/composition-api';

export default {
  components: {
    NuxtTransformImage: () =>
      import(/* webpackChunkName: "chunk-ui-molecules" */ '@/components/UI/molecules/NuxtTransformImage')
  },
  props: {
    text: {
      type: String,
      required: true
    },
    textLink: {
      type: String,
      required: false
    },
    textThumbnail: {
      type: String,
      required: false
    },
    link: {
      type: String,
      required: false
    },
    id: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const htmlHeadline = computed(() => {
      const html = props.text;
      if (props.link && props.textLink) {
        return html.replace(props.textLink, `<a id="${props.id}" href="${props.link}"><u>${props.textLink}</u></a>`);
      }
      return html;
    });
    return {
      htmlHeadline
    };
  }
};
