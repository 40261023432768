//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ref, watch } from '@nuxtjs/composition-api';
import debounce from 'lodash/debounce';
export default {
  name: 'SliderRange',
  props: {
    min: {
      type: Number,
      default: 1
    },
    max: {
      type: Number,
      default: 50
    },
    step: {
      type: Number,
      default: 1
    },
    placeholder: {
      type: String,
      default: 'Anzahl Teilnehmende'
    },
    showInfo: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: ''
    },
    linkText: {
      type: String,
      default: ''
    },
    link: {
      type: String,
      default: ''
    },
    defaultValue: {
      type: Number,
      default: 1
    },
    isExternalLink: {
      type: Boolean,
      default: false
    },
    showSlider: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const value = ref(props.defaultValue);

    function getValue(value) {
      if (value !== undefined) emit('value', parseInt(value));
    }

    const debouncedSlideEvent = debounce((v) => {
      value.value = v;
    }, 100);

    // this is needed for mobile behaviour
    watch(value, () => {
      getValue(value.value);
    });

    return {
      getValue,
      debouncedSlideEvent,
      value
    };
  }
};
